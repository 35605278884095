import { writable } from "svelte/store";
import { showToast } from "../../assets/js/src/general/utils.js";
import { addWebsocketListener } from "../../assets/js/src/general/websocket.js";
import { ApiError } from "../api/sw-api-error.js";
import { ToastError } from "../api/sw-toast-error.js";

const MetaStore = () => {
  let data = {
    fetching: false, // For spinners
    firstFetchDone: false, // For skeleton loading / once
  };

  const subscribers = new Set();
  const subscribe = (subscriber) => {
    subscribers.add(subscriber);
    subscriber(data);
    return () => subscribers.delete(subscriber);
  };

  const set = (newData) => {
    data = newData;
    subscribers.forEach((subscriber) => subscriber(data));
  };

  const update = (updater) => {
    data = updater(data);
    subscribers.forEach((subscriber) => subscriber(data));
  };

  return {
    subscribe,
    set,
    update,

    // utility methods to access the live data without subscribing. If
    // you're looking to set skeleton loading or spinners, you
    // probably want to subscribe to the store itself.
    fetching: () => data.fetching,
    firstFetchDone: () => data.firstFetchDone,
  };
};

export const SwApiStore = ({ get, onMessage, init, cacheKey }) => {
  const meta = MetaStore();

  const wrappedInit = () => {
    if (cacheKey) {
      const cachedData = localStorage.getItem(cacheKey);
      if (cachedData) {
        try {
          return JSON.parse(cachedData);
        } catch (e) {
          console.warn("Failed to parse cached data", cachedData, e);
        }
      }
    }
    return init();
  };

  // Store content
  let data = wrappedInit();

  let abortController = null;
  /** @param {[string]} message */
  const cancelFetch = (message) => {
    if (abortController) {
      abortController.abort(new DOMException(message, "AbortError"));
    }
    abortController = null;
  };

  // Implementation of svelte store contract methods
  const subscribers = new Set();
  const subscribe = (subscriber) => {
    subscribers.add(subscriber);
    subscriber(data);
    return () => subscribers.delete(subscriber);
  };

  const notifySubscribers = () =>
    subscribers.forEach((subscriber) => subscriber(data));

  // Todo - consider adding meta as an optional second argument to the updater function?
  const update = (updater) => {
    data = updater(data);
    notifySubscribers();
  };

  const getAndUpdateStore = async () => {
    cancelFetch("New request");
    meta.update((value) => ({ ...value, fetching: true }));
    abortController = new AbortController();
    const { signal } = abortController;
    return get({ signal })
      .then((newData) => {
        if (!newData) {
          throw new Error("No data returned from API");
        }

        if (newData && cacheKey) {
          localStorage.setItem(cacheKey, JSON.stringify(newData));
        }
        data = newData;
        notifySubscribers();
        meta.set({ fetching: false, firstFetchDone: true });
        return data;
      })
      .catch((e) => {
        const { cause } = e;
        if (
          e instanceof ToastError &&
          !(cause instanceof DOMException && cause.name === "AbortError") &&
          !(
            cause instanceof ApiError &&
            cause.status === 401 &&
            window.location.pathname.startsWith("/signin")
          )
        ) {
          showToast(e);
        }
        meta.update((value) => ({ ...value, fetching: false }));
      });
  };

  const once = async () => {
    if (meta.firstFetchDone()) return data;
    if (!meta.fetching()) return getAndUpdateStore();
    return new Promise((resolve) => {
      const unsubscribe = subscribe(() => {
        const resolveWhenReady = () => {
          if (meta.firstFetchDone()) {
            unsubscribe();
            resolve(data);
          } else {
            setTimeout(resolveWhenReady, 10);
          }
        };
        resolveWhenReady();
      });
    });
  };

  const reset = () => {
    cancelFetch("Reset");
    meta.set({
      fetching: false,
      firstFetchDone: false,
    });
    data = init();
    notifySubscribers();
  };

  if (onMessage)
    addWebsocketListener((message) =>
      onMessage({ update, get: getAndUpdateStore, message }),
    );

  return {
    subscribe,
    get: getAndUpdateStore,
    once,
    meta: {
      // only surface subscribe method to make read-only.
      subscribe: meta.subscribe,
      fetching: meta.fetching,
      firstFetchDone: meta.firstFetchDone,
    },
    update,
    reset,
  };
};

/** @deprecated The method is deprecated - prefer SwArgsApiStore */
export const SwDerivedApiStore = ({
  get,
  init,
  onMessage,
  argsStore,
  argsTransform = (x) => x,
  dataTransform = (x) => x,
  resetBeforeFetch = true,
}) => {
  let args;
  let data;

  // opts included for abort controller signal
  const getWithArgs = (opts) => get(...args, opts);

  const apiStore = SwApiStore({
    get: getWithArgs,
    init,
    onMessage,
  });

  const getOrReset = () => {
    if (args) {
      if (resetBeforeFetch) {
        apiStore.reset();
      }
      apiStore.get();
    } else {
      apiStore.reset();
    }
  };

  argsStore.subscribe((res) => {
    const newArgs = argsTransform(res);
    if (newArgs && args && JSON.stringify(newArgs) === JSON.stringify(args))
      return;
    args = newArgs;
    getOrReset();
  });

  const subscribers = new Set();

  const subscribe = (subscriber) => {
    subscribers.add(subscriber);

    subscriber(data);
    return () => subscribers.delete(subscriber);
  };

  apiStore.subscribe((res) => {
    data = dataTransform(res);
    subscribers.forEach((subscriber) => subscriber(data));
  });

  const reset = () => {
    apiStore.reset();
    data = init();
    subscribers.forEach((subscriber) => subscriber(data));
  };

  return { subscribe, reset, meta: apiStore.meta, get: getOrReset };
};

export const SwArgsApiStore = ({
  get,
  init,
  onMessage,
  resetBeforeFetch = true,
}) => {
  const argsStore = writable(null);
  const store = SwDerivedApiStore({
    get,
    init,
    onMessage,
    resetBeforeFetch,
    argsStore,
  });
  return {
    ...store,
    set: argsStore.set,
    update: argsStore.update,
  };
};
