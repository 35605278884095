import { ApiError } from "./sw-api-error";

export class ToastError extends Error {
  constructor(message, opts = {}) {
    super(message, opts);
    this.name = "ToastError";
    this.message = message;

    this.subtitle = "";
    this.title = "An error occurred";
    this.type = "danger";

    if (opts.cause instanceof ApiError) {
      const apiError = opts.cause;
      if (apiError.status === 401) {
        this.title = "Your session has expired";
        this.subtitle = "Redirecting to signin page.";
      } else if ([400, 402].includes(apiError.status)) {
        try {
          this.subtitle = JSON.parse(apiError.message);
        } catch {
          this.subtitle = "";
        }
      } else if (apiError.status === 0) {
        this.title = "A network error occurred";
        this.subtitle = "Please check your internet connection and try again.";
      }
    }
  }
}
