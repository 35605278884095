/**
 * Custom error class for API errors.
 *
 * @extends {Error}
 */
export class ApiError extends Error {
  /**
   * Creates an instance of ApiError.
   *
   * @param {number} status - The HTTP status code of the error.
   * @param {string} message - The error message.
   */
  constructor(status, message) {
    super(message);
    this.status = status;
    this.name =
      {
        400: "Client error",
        401: "Unauthorized",
        402: "Payment required",
        404: "Not found",
        0: "Network error",
      }[status] || "Unknown error";
  }
}
